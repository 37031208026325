<template>
    <header class="banner mb-1">
        <nav class="nav" v-if="mostrarTop">
            <div class="logo"><h1>
            <router-link :to="{name:'home'}"> Prixis </router-link>    
            </h1></div>
            <div class="main">
                <ul>
                    <li>    
                        <router-link :to="{name:'home'}">
                            Inicio
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'Directorio'}">
                            Directorio
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'QuienesSomos'}">
                            Sobre Nosotros
                        </router-link>
                    </li>
                    <li><a href="https://prixapi.prixisevents.com#contacto-id">Contacto</a></li>
                </ul>
            </div>
        </nav>
        <div class="capa"></div>
        <div class="info">
            <h1>Bienvenidos a Prixis</h1>
            <p>       
            ¡Bienvenidos al fascinante mundo de Prixis, 
            tu guía para los momentos más extraordinarios de la vida! Prepárate para 
            sumergirte en un universo de celebraciones inolvidables, 
            donde cada detalle está meticulosamente cuidado para hacer de tus eventos festivos, 
            bodas, quinces y cumpleaños una experiencia única y memorable.
            </p>
        </div>
    </header>
</template>

<script>
export default {
    data(){
        return{
            mostrarTop: false
        }
    },
    created:async function(){
        this.ajustarPantalla()
        window.addEventListener("resize", this.tamañoDelCiente);
    },
    methods:{
        /**
         * Ajusta el responsive al tamaño inicial de la pantalla del cliente
         */
        ajustarPantalla(){
            if(document.documentElement.clientWidth <= 767){
                this.mostrarTop = false
            }

            if(document.documentElement.clientWidth > 767){
                this.mostrarTop = true
            }
        },
        /**
         * Detecta los cambios en el tamaño de la pantalla del cliente
         */
        tamañoDelCiente(e) {
            if(document.documentElement.clientWidth <= 767){
                this.mostrarTop = false

            }

            if(document.documentElement.clientWidth > 767){
                this.mostrarTop = true
            }
        },
    }
}
</script>

<style scoped>
/**TopBar en modo escritorio - Inicio */
.nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.7s;
    padding: 30px 70px;
    z-index: 10;
}
.main{
    display: flex;
    justify-content: center;
    align-items: center !important;;
}
.main ul{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center !important;
}
.main ul li{
    list-style: none;
}
.main ul li a{
    position: relative;
    font-family: helvetica;
    margin: 0 15px;
    text-decoration: none;
    color: #fff;
    letter-spacing: 2px;
    font-weight: 600;
    transition: 0.7%;
    padding: 7px 13px;
    border-radius: 3px;
}
a.active, a:hover{
    background: #ffe0e0;
    transition: .5s;
    text-decoration: none;
    color: black !important;
}
.nav .logo{
    position: relative;
    transition: 1.5s;
    margin-left: 70px;
}
.nav .logo a{
    color: #fff;
    font-family: helvetica;
    text-decoration: none;
    padding: 7px 13px;
    border-radius: 3px;
}
.abajo{
    background: #000;
    padding: 15px 70px;
}
.abajo .logo a,
.abajo ul li a{
    color: #fcc8c8;
}
/**TopBar en modo escritorio - Fin */
/**Seccion del baner - Inicio */
.banner{
    width: 100%;
    height: 100vh;
    animation: banner 30s infinite linear alternate;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0!important;
}

@keyframes banner{
    0%, 20% {
        background-image: url(../assets/imagenes/img1.jpg);
    }
    25%, 45% {
        background-image: url(../assets/imagenes/img2.jpg);
    }
    50%, 70% {
        background-image: url(../assets/imagenes/img3.jpg);
    }
    75%, 100% {
        background-image: url(../assets/imagenes/img4.jpg);
    }
}
.capa{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
}
.info{
    z-index: 1;
    width: 100%;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 4rem 20rem;
}
.info h1{
    color: black;
    font-size: 5rem;
    margin-bottom: 5px;
    letter-spacing: 1px;
    font-family: 'Great Vibes', cursive;
}
.info p{
    color: black;
    font-size: 1.2rem;
    line-height: 1.7;
}

@media (max-width: 767px){
.banner{
    height: 95vh;
}
.info{
    padding: 1rem 2rem;
}
.info h1{
    font-size: 4rem;
}
.info p{
    font-size: 1rem;
}
}
/**Seccion del baner - Fin */
</style>