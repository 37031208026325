<template>
  <div class="home">
      <div class="section-directorio-empresas pb-5">
          <h3 class="text-center h1 title-directorio">Directorio Prixis</h3>
          <div class="sec-search">
                <form class="form-buscar w-50" v-on:submit.prevent="buscarEmpresas">
                    <div class="form-group">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
                            </div>
                            <input type="text" class="form-control" v-model="formBusqueda.nombre" placeholder="Buscar empresa" aria-label="buscador">
                        </div>

                        <!-- <small id="helpId" class="form-text text-muted">Busca una empresa por su nombre</small> -->
                    </div>

                    <!-- <div class="btn-group" role="group" aria-label="">
                        <button type="submit" class="btn btn-success">Buscar</button>
                    </div> -->
                </form>
          </div>
          <div class="row p-0 m-0">
                <div class="sec-buscar col-12 col-md-5 img-directorio">

                    <!-- <form class="form-buscar" v-on:submit.prevent="buscarEmpresas">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
                                </div>
                                <input type="text" class="form-control" v-model="formBusqueda.nombre" placeholder="Buscar empresa" aria-label="buscador">
                            </div>

                            <small id="helpId" class="form-text text-muted">Busca una empresa por su nombre</small>
                        </div>

                        <div class="btn-group" role="group" aria-label="">
                            <button type="submit" class="btn btn-success">Buscar</button>
                        </div>
                    </form> -->

                </div>
              <div class="col-12 col-md-7">
                  <div class="list-empresas">

                      <div v-for="empresa in empresas" :key="empresa.id" class="empresa-body d-flex shadow rounded border mt-3">
                            <img :src="'https://prixapi.prixisevents.com'+empresa.imagen" alt="img-empresa">
                            <div class="description-emp">
                                <p class="tittle">{{empresa.nombre}}</p>
                                <p class="text-description">{{empresa.descripcion}}</p>
                                <div class="socials-empresa mb-3" >
                                    <a v-for="social in empresa.redesSociales" :key="social.id" :href="social.pivot.valor" class="text-dark">
                                        <i  :class="social.imagen"></i>
                                    </a>
                                </div>

                                <router-link :to="{name:'Empresas', params:{id:empresa.id}}" class="btn btn-primary mt-auto">
                                    Ver más
                                </router-link>
                            </div>
                      </div>

                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Directorio',
  data(){
        return{
            empresas:[], //Lista que almacena las empresas optenidas del metodo "consultarEmpresas"
            NombServicios: [{id:1,nombre:'serv-1'},{id:2,nombre:'serv-2'},{id:3,nombre:'serv-3'}],
            selectedModal:[],
            formBusqueda: {},  //Objeto que almacena los valores de busqueda del formulario
            token:null
        }
    },
    created:function(){
        this.obtenerToken();
        this.consultarEmpresas();
    },
    methods:{
        obtenerToken(){
            this.token = sessionStorage.getItem('token')
            console.log(this.token)
        },
        /**
         * Metodo para obtener los usuarios registrados
         */
        consultarEmpresas(){
            fetch('https://prixapi.prixisevents.com/api/empresas',{  //http://prixisapi.net/empresas
                headers:{
                    'Authorization': 'Bearer '+ this.token
                }
            })
            .then(respuesta=>respuesta.json())
            .then((respuestaDatos)=>{
                console.log(respuestaDatos)
                if(respuestaDatos.res == '200'){
                    this.empresas = respuestaDatos.msg;
                }else{
                    console.log("Error al obtener las empresas registradas")
                }
            })
            .catch(console.log)
        },
        /*
        * Metodo para buscar empresas
        */
        buscarEmpresas(){
            var datosEnviar = {
                nombre: this.formBusqueda.nombre
            }

            fetch('https://prixapi.prixisevents.com/api/empresas/filtrar',
            {
                method:"POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ this.token
                },
                body: JSON.stringify(datosEnviar)
            })
            .then(respuesta=>respuesta.json())
            .then(datosRespuesta=>{
               this.empresas = datosRespuesta.msg
            })
            .catch(error => {
             console.log(error)
            })
        }
    }

}

// window.addEventListener("scroll",function(){
//     var nav = document.querySelector("nav")
//     nav.classList.toggle("abajo",window.scrollY>100);
// })
</script>

<style scoped>
html, body {
    width: 100%!important;
    overflow-x: hidden!important;
}
/**Encabezado */
.title-directorio{
    background-color: black;
    color: #fcc8c8 !important;
    padding: 15px 0;
    margin: 0;
}
/**Seccion filtrado y busqueda */
.sec-search{
    background: #ffe0e0;
    padding: 8rem 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
}

.form-buscar .btn{
    background-color: black !important;
    color: #fcc8c8 !important;
    border: 2px solid #fcc8c8 !important;
}
.form-buscar .input-group-text{
    background-color: black !important;
    border: solid 1px black !important;
    color: #fff !important;
}
.text-muted{
    color: black !important;
}
.form-control{
    /* background-color: #ffe0e0 !important; */
    border: 1px solid black;
    border-left: none;
    color: black;
}
.form-buscar .form-text{
    color: black;
}

/** Secc Directorio */

.img-directorio{
    background-image: url('@/assets/imagenes/directorio2.png');
    background-position: bottom;
    background-size: 70%;
    background-repeat: no-repeat;
    padding: 10rem 0;
}

.section-directorio-empresas{
    background: #ffffff;
}


.list-empresas{
    display: flex;
    flex-direction: column;
    align-items: center;
}

/**Cuerpo de las card */
.empresa-body{
    width:60%;
    background: #ffe0e0;
    padding: 20px;
    color: black;
    border: solid 1px #ffe0e0 !important;
}
.empresa-body .btn{
    background-color: black !important;
    color: #fcc8c8 !important;
    border: solid 2px black !important;
}
.empresa-body img{
    border-radius: 10px !important;
    border: solid 1px black !important;
}
.description-emp{
    width: 100%;
}
.description-emp .tittle{
    font-size: 24px;
}
.description-emp .socials-empresa i{
    margin-right: 10px;
    font-size: 24px;
}
.empresa-body img{
    width: 100px;
    height: 100px;
    margin-right: 20px;
}
.text-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;/*si no se declara añade los ... pero muestra todo el texto*/
}

@media (max-width: 767px){
    .sec-search{
        padding: 4rem 0;
        margin-bottom: 1rem;
    }
    .form-buscar{
        width: 80% !important;
    }
    .section-directorio-empresas .row .sec-buscar{
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }
    .img-directorio{
        padding: 7rem 0;
    }
    .empresa-body{
        width: 95%;
    }
    .empresa-body .tittle{
        margin-bottom: 0px !important;
    }
    .empresa-body p{
        font-size: 12px;
    }
    
}
</style>
