<template>
    <div class="home">
    <section class="content-section pt-5">

        <div class="sobre-nosotros row m-0 p-0">
            <div class="div-img-sobre-nosotros col-5 d-flex p-0">
                <div class="cont-img ml-auto">
                    <img id="img-about" :src="'https://prixapi.prixisevents.com'+ empresa.imagen_inicio " alt="" class="rounded pb-0">
                </div>
            </div>

            <div class="cont-about-desc col-12 col-md-7 d-flex flex-column pl-5 py-5">
                <div class="img-movil mx-auto"></div>
                <h3 id="title-about" class="h3 mt-4 title-about">{{empresa.nombre}}</h3>
                <p id="text-about" class="pr-5 text-about">{{empresa.descripcion}}</p>
                <a id="btn-about" href="#contactos-map" class="btn btn-success w-25 mt-auto">Contáctenos</a>
            </div>
        </div>

        <div class="servicios p-0 m-0" id="servicios" v-if="servicios.length > 0">
            <h1 class="mb-1 mt-5 text-center" style="font-family:Great Vibes; font-size:60px; font-weight:medium">Servicios</h1>
            <hr class="mb-2" style="
                    border: 0;
                    width:60%;
                    margin: auto;
                    color: black;
                    opacity: 1;
                    height: 1px;
                    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));"
                    >

            <div class="row justify-content-between p-0 m-0 mt-5">

                <div class="services-background col-12 col-md-6 mb-5 pl-5" v-for="servicio in servicios" :key="servicio.id">
                    <div class="back-1">
                        <div class="back-1-1" :style="'background-image: url(https://prixapi.prixisevents.com'+servicio.imagen+')'"></div>
                    </div>
                    <div class="back-2">
                        <div class="services-body">
                            <div class="services-body-text">
                                <h3>{{servicio.nombreReal.nombre}}</h3>
                                <hr>
                                <p>{{servicio.descripcion}}</p>
                            </div>
                            
                            <div class="services-body-btn">
                                <router-link :to="{name:'Servicios/Listar', params:{id:servicio.id}}" class="btn btn-primary">
                                    Ver ofertas
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="mb-5" style="border: 0;
                    width:60%;
                    margin: auto;
                    color: black;
                    opacity: 1;
                    height: 2px;
                    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));">
            </div>

        </div>

        <div class="paquetes p-0 m-0 py-5" v-if="paquetes.length > 0">
            <h3 class="mb-5 text-center">Paquetes <span>&</span> Precios</h3>

            <div class="paquetes-content">

                <button class="text-dark btn btn-link mx-2 btn-arrow" v-on:click="anteriorPKT"><i class="fas fa-chevron-left"></i></button>

                <div class="content-card-paquetes">
                    <div class="mx-4 mb-3" v-for="paquete in filtrarPaquetes" :key="paquete.id">         
                        <div class="table-content p-3 d-flex flex-column justify-content-between align-items-center mx-auto">
                            <div class="w-100">
                                <h5 class="fw-bolder mb-4">{{paquete.nombre}}</h5>

                                <pre class="p ml-3">{{paquete.descripcion}}</pre>
                            </div>
                            
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-dark text-danger btn-1" style="border-radius: 50px 0px 0px 50px; border-right: 2px gray solid;">${{paquete.precio}}</button>
                                <button type="button" class="btn btn-dark btn-2" style="border-radius: 0px 50px 50px 0px">CONTÁCTENOS</button>                      
                            </div>
                        </div>
                    </div>
                </div>

                <button class="text-dark btn btn-link mx-2 btn-arrow" v-on:click="siguientePKT"><i class="fas fa-chevron-right"></i></button>

            </div>
        </div>

        <div class="secc-contacto row p-0 m-0">
            <div class="col-12 col-md-6 img-contacto"></div>
            <div class="col-12 col-md-6 datos-contactos">
                <h2 class="mb-4">Hola!</h2>
                <div class="img-contact-movil"></div>
                <h4 class="mb-3">Necesitas Información?</h4>
                <div class="contact">
                    <h5><b>Contáctos:</b></h5>
                    <ul class="mt-3 h5">
                        <li class="mt-1"><i class="fas fa-phone"></i> {{empresa.telefono}}</li>
                        <li class="mt-2"><i class="fas fa-envelope"></i> {{empresa.correo}}</li>
                    </ul>
                </div>
                
                <hr class="w-50 mx-auto my-3">

                <div class="social">
                    <h5><b>Siguenos en:</b></h5>
                    <ul class="pl-3 mt-3 h2 d-flex justify-content-center">
                        <li v-for="social in empresa.redesSociales" :key="social.id" class="ml-2">
                            <i :class="social.imagen"></i>
                        </li>
                    </ul>
                </div> 
            </div>
        </div>
        
    </section>
  </div>
</template>

<script>
export default {
    name: 'InicioEmpresasView',
    data(){
        return{
            empresa:{}, //Variable que almacena los datos de la empresa registrada.
            servicios:[], //Lista que almacena los servicios optenidos del metodo "obtenerServicios" de una empresa
            paquetes:[], //Lista que almacena los paquetes y precios obtenidos del método "obtenerPaquetes" de una empresa
            primerPKT: 0,
            ultPKT: 3,
            cantPKTs:3,
            token:null
        }
    },
    created:async function(){
        this.obtenerToken()
        await this.obtenerEmpresa()
        await this.obtenerServicios()
        await this.ObtenerAlturaImagen()
        await this.obtenerPaquetes()
        this.ajustarPantalla()
        window.addEventListener("resize", this.tamañoDelCiente);
    },
    destroyed() {
        window.removeEventListener("resize", this.tamañoDelCiente);
    },
    methods:{
        obtenerToken(){
            this.token = sessionStorage.getItem('token')
        },
        /**
         * Metodo para obtener una empresa registrada
         */
        obtenerEmpresa(){
             return new Promise ((resolve,reject)=>{

                fetch('https://prixapi.prixisevents.com/api/empresas/' + this.$route.params.id,{
                    headers:{
                    'Authorization': 'Bearer '+ this.token
                    }
                })
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{                                       
                    console.log(respuestaDatos)
                    if(respuestaDatos.res == '200'){
                        this.empresa = respuestaDatos.msg; 
                        resolve()   
                    }else{
                        console.log("Error al obtener las empresas registradas")
                        resolve()   
                    }             
                })
                .catch(console.log)
            })
        }, 
        /**
         * Metodo para obtener una empresa registrada
         */
        obtenerServicios(){
             return new Promise ((resolve,reject)=>{

                fetch('https://prixapi.prixisevents.com/api/empresas/servicios/' + this.$route.params.id,{
                    headers:{
                    'Authorization': 'Bearer '+ this.token
                    }
                })
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{        
                        if(respuestaDatos.res == '200'){
                            this.servicios = respuestaDatos.msg; 
                            resolve()   
                        }else{
                            console.log("Error al obtener los servicios registrados")
                            resolve()   
                        }                
                })
                .catch(console.log)
            })
        }, 
         /**
         * Metodo para obtener los paquetes y precios de una empresa
         */
        obtenerPaquetes(){
            return new Promise ((resolve,reject)=>{

            fetch('https://prixapi.prixisevents.com/api/paquetes/' + this.$route.params.id,{
                headers:{
                    'Authorization': 'Bearer '+ this.token
                }
            })
            .then(respuesta=>respuesta.json())
            .then((respuestaDatos)=>{      
                    this.paquetes = respuestaDatos.msg;                        
                    resolve()                
            })
            .catch(console.log)
            })
        }, 
        /**
         * Metodo para obtener la altura de la imagen de la seccion Quienes Somos
         */
        ObtenerAlturaImagen(){
            var tittle = document.getElementById('title-about').clientHeight;
            var text = document.getElementById('text-about').clientHeight;
            var btn = document.getElementById('btn-about').clientHeight;
            var altura = tittle + text + btn + 80

            if(altura <= 500){
                altura = 500
            }else{
                altura = 676
            }

            document.getElementById("img-about").style.height = altura +"px";
        },
        /**
         * Aumenta el valor del primerPKT del filtrado de paquetes para que muestre el siguiente paquete de la lista
         */
        siguientePKT(){
            if( (this.primerPKT + this.cantPKTs) < this.paquetes.length){
                this.primerPKT ++
            }
        },
        /**
         * Disminuye el valor del primerPKT del filtrado de paquetes para que muestre el anterior paquete de la lista
         */
        anteriorPKT(){
            if( (this.primerPKT) > 0){
                this.primerPKT --
            }
        },
        /**
         * Ajusta el responsive al tamaño inicial de la pantalla del cliente
         */
        ajustarPantalla(){
            if(document.documentElement.clientWidth <= 767){
                this.cantPKTs = 1
            }

            if(document.documentElement.clientWidth > 767){
                this.cantPKTs = 3
            }
        },
        /**
         * Detecta los cambios en el tamaño de la pantalla del cliente
         */
        tamañoDelCiente(e) {
            if(document.documentElement.clientWidth <= 767){
                this.cantPKTs = 1
            }

            if(document.documentElement.clientWidth > 767){
                this.cantPKTs = 3
            }
        },
    },
    computed:{
        filtrarPaquetes: function(){
            if(this.paquetes.length > 0){
                var listPaquete = []
                this.ultPKT = this.cantPKTs + this.primerPKT

                for(var i= this.primerPKT; i< this.ultPKT && i< this.paquetes.length; i++){
                    listPaquete.push(this.paquetes[i])
                }
                return listPaquete
            }
        }
    }
    
}

window.addEventListener("scroll",function(){
    var nav = document.querySelector("nav")
    nav.classList.toggle("abajo",window.scrollY>100);
})
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

html, body {
    width: 100% !important;
    overflow-x: hidden !important;
}

/* Seccion sobre nosotros - INICIO */
.sobre-nosotros{
    margin-bottom: 50px !important;
}

.sobre-nosotros-card{
    background-color: white;
}

.sobre-nosotros .cont-img{
    background: #ffe0e0;
    padding: 1px 1px 0 0;
}

.sobre-nosotros .col-5{
    background-image: url('@/assets/imagenes/particulas.png');
    background-repeat: repeat;
    background-position: center;
    background-size: auto;
}

.sobre-nosotros .cont-img img{
    width: auto;
    height: auto;
    min-width: 200px;
    max-width: 500px;
}

.sobre-nosotros .cont-about-desc{
    background-color: #ffe0e0;
    margin-bottom: 80px;
    word-wrap: break-word;
    border-top: black dashed 2px;
    border-bottom: black dashed 2px;
}

.sobre-nosotros .cont-about-desc .title-about{
    font-size: 40px;
    margin-bottom: 25px;
}

.sobre-nosotros .cont-about-desc .text-about{
    font-size: 18px;
}

.sobre-nosotros .cont-about-desc .btn{
    background-color: black !important;
    color: #fcc8c8 !important;
    border: 2px solid #fcc8c8 !important;
    padding: 10px;
}

.img-movil{
    display: none;
}

@media (max-width: 767px){
    .content-section{
        padding-top: 0px !important;
    }
    .sobre-nosotros{
        margin: 0;
    }
    .div-img-sobre-nosotros{
        display: none !important;
    }

    .cont-about-desc{
        margin: 0 !important;
        padding: 100px 15px !important;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    .cont-about-desc .text-about{
        text-align: justify;
        padding: 0 !important;
    }

    .img-movil{
        display: block;
        background-image: url('@/assets/imagenes/ppp.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin-bottom: 30px;
    }

    #btn-about{
        margin-top: 50px !important;
        padding: 10px;
        width: 50% !important;
    }
}


/* Seccion sobre nosotros - FIN */

/*Seccion servicios - Inicio */

.services-background{
    height: 534px;
    width: 582px;
    display: flex;
}

.back-2{
    height: 534px;
    width: 291px;
    display: flex;
    align-items: center;
}

.back-1-1{
    height: 534px;
    width: 291px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#servicios{
    margin: 0 !important;
    padding: 0 !important;
    margin-top: 100px;

}

.services-body{
    background-color: white;
    height: 409px;
    width: 300px;
    margin-top: 60px;
    margin-left: -100px;
    box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.2);
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 30px;
    padding-bottom: 30px ;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.services-body .btn{
    background-color: black !important;
    color: #fcc8c8 !important;
    border: 2px solid #fcc8c8 !important;
    padding: 10px;
}

.services-body h3{
    color: #000;
    font-size: 28px;
}

.services-body p{
    color: #6d7180;
    font-size: 18px;
}

.services-body-text hr{
    width: 100%;
    margin: 20px 0px;
    margin-left: 80px;
    border: 1px solid black;
    color: black;
    background: black;
}

@media (max-width: 767px){
    #services{
        max-width: 100%;
        overflow-x: none !important;
    }
    .services-background{
        margin-bottom: 6rem !important;
        height: 364px;
        width: 221px;
        padding: 10px !important;
    }

    .back-1{
        display: flex;
        align-items: center;
    }

    .back-2{
        height: 364px;
        width: 221px;
    }

    .back-1-1{
        height: 364px;
        width: 221px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .services-body{
        height: 309px;
        width: 200px;
        margin-top: 150px;
    }

    .services-body h3{
        font-size: 20px;
    }

    .services-body p{
        font-size: 14px;
    }

    .services-body-text hr{
        margin-left: 20px;
    }
    
}
/*Seccion servicios - FIN */
/* Seccion paquetes y precios - Inicio */
.content-card-paquetes{
    display: flex;
    align-items: center;
}
.paquetes{
    background: #ffe0e0;
    width: 100%;
}
.paquetes-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
}
.paquetes h3 span{
   background: #fff;
   border-radius: 50%;
   padding: 5px 10px;
}
.table-content{
    background: #fff;
    width: 300px;
    height: 350px;
}
.table-content p{
    font-size: 14px;
    font-weight: lighter;
}

.paquetes .btn-arrow{
    font-size: 24px;
}

@media (max-width: 767px){
    .content-card-paquetes{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .table-content{
        width: 250px !important;
        height: 300px !important;
    }
}
/* Seccion paquetes y precios - Fin */
/* Seccion contactos */

.secc-contacto{
    text-align: center;
    margin-top: 150px;
    background-color: #ffe0e0;
}

.secc-contacto .text-contact{
    font-size: 20px;
} 

.secc-contacto h2{
    color: black;
    font-size: 50px;
    font-weight: bold;
}

.secc-contacto h4{
    color: black;
    font-size: 30px;
    font-weight: bold;
}

.datos-contactos{
    padding: 100px;
}

 .img-contacto{
    background-image: url('../../assets/imagenes/contacto3.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
 }

 .img-contact-movil{
    display: none;
 }

 .social li{
    list-style:none !important
 }

 .contact li{
    list-style:none !important
 }


@media (max-width: 767px){
    .datos-contactos{
        padding: 100px 15px!important;
    }
    .secc-contacto{
        margin: 0;
    }
    .img-contacto{
        display: none;
    }

    .img-contact-movil{
        display: block;
        background-image: url('../../assets/imagenes/contacto3.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        widows: 300px;
        height: 300px;
    }
}

/*Fondos*/
#img-back-traje-1{
    background-image: url("../../assets/imagenes/servicios/trajes1.jpg");
}

#img-back-traje-2{
    background-image: url("../../assets/imagenes/servicios/trajes2.jpg");;
}

#img-back-peinado-1{
    background-image: url("../../assets/imagenes/servicios/peinado1.jpg");;
}

#img-back-peinado-2{
    background-image: url("../../assets/imagenes/servicios/peinado2.jpg");;
}
</style>