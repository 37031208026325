<template>
    <!-- <div class="servicios p-5 d-flex flex-column justify-content-center align-items-center"> -->
    <div class="d-flex flex-column justify-content-center align-items-center"> 
        <div class="top-main">
            <div class="atras w-100">
                <hr class="mb-2" style="
                        border: 0;
                        width:25%;
                        margin: auto;
                        color: black;
                        opacity: 1;
                        height: 1px;
                        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));"
                        >

                <div class="w-100 d-flex justify-content-center align-items-center">
                    <h3>{{servicio.nombreReal.nombre}} |</h3>
                    <router-link :to="'/empresas/'+idEmpresa" class="btn btn-link"><p class="h6 font-weight-bolder mt-1">Volver a la empresa</p></router-link>
                </div>

                <hr class="mb-0 mt-2" style="
                            border: 0;
                            width:25%;
                            margin: auto;
                            color: black;
                            opacity: 1;
                            height: 1px;
                            background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));"
                            >
            </div>
        </div>

        
        <!-- <div class="sobre-nosotros row m-0 p-0">
            <div class="div-img-sobre-nosotros col-5 d-flex p-0">
                <div class="cont-img ml-auto">
                    <img id="img-about" :src="directorioImagenes + servicio.imagen" alt="" class="rounded pb-0">
                </div>
            </div>

            <div class="cont-about-desc col-12 col-md-7 d-flex flex-column ps-5 py-5">
                <div class="img-movil mx-auto"></div>
                <h3 id="title-about" class="h3 mt-4 title-about">{{servicio.nombreReal.nombre}}</h3>
                <p id="text-about" class="pe-5 text-about">{{servicio.descripcion}}</p>
                <a id="btn-about" href="#contactos-map" class="btn btn-outline-dark mt-auto mb-4 w-25 btn-about">Contáctenos</a>
            </div>
        </div> -->

        <div class="sobre-nosotros row container-fluid">
            <div id="img-sobre-nosotros" :style="'background-image: url(' + directorioImagenes + servicio.imagen + ')'"></div>
            <div class="sobre-nosotros-desc">
                <h3 id="title-about" class="h3 mt-4 title-about">{{servicio.nombreReal.nombre}}</h3>
                <p id="text-about" class="pe-5 text-about">{{servicio.descripcion}}</p>
                <a id="btn-about" href="#contactos-map" class="btn btn-outline-dark mt-3">Contáctenos</a>
            </div>
        </div>

        <div class="galeria container mb-5">
            <h1 class="mb-1 mt-5 text-center" style="font-family:'Great Vibes', cursive; font-size: 4rem; font-weight:medium; color:black">Galería</h1>
            <hr class="hr-st">

            <!-- <div class="card-columns mt-3">
                <div class="card" v-for="imagen in imagenes" :key="imagen.id">
                    <img :src="directorioImagenes + imagen.url" class="card-img-top img-s" @click="openPopup()">
                </div>
            </div> -->

            <div class="gallery">
                <div class="grid" ref="grid">
                <div v-for="imagen in imagenes" :key="imagen.id" class="grid-item">
                    <img :src="directorioImagenes + imagen.url" alt="Image" @load="masonryLayout" @click="openPopup()"/>
                </div>
                </div>
            </div>
        </div>

        <!-- Ventana Modal para las imagenes -->
        <!-- <div class="modal-s" id="myModalImagen">
            <span id="btnClose-s">&times;</span>
            <img class="modal-content-s" id="modal-img" />
        </div> -->

        <!-- Modal -->

        <!-- <b-modal
        id="myModalImagen"
        class="modal-s"
        ref="modal"
        centered
        hide-header
        hide-footer
        body-class = "d-flex justify-content-center p-0 m-0"
        content-class="w-auto"
        modal-class="d-flex justify-content-center"
        >
        <img class="modal-content-s" id="modal-img" :src="srcImgModal"/>
        </b-modal> -->

        <!-- Final-Modal -->

        <!-- Popup -->
        <!-- <button @click="openPopup">Abrir Popup</button> -->
        <div v-if="isPopupOpen" class="popup">
            <div class="body-popup d-flex flex-column">
                <carousel :per-page="1" :navigationEnabled="true">
                    <slide v-for="imagen in imagenes" :key="imagen.id" class="d-flex">
                        <img :src="directorioImagenes + imagen.url" class="mx-auto"/>
                    </slide>
                </carousel>
                <button @click="closePopup" class="mx-auto mt-4 btn btn-link"><b-icon-x></b-icon-x></button> 
            </div>
        </div>
        <!-- Fin - Popup -->
        
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import Masonry from 'masonry-layout';

export default {
    name: 'ServiciosListarView',
    components: {
        Carousel,Slide
    },
    data(){
        return{
            servicio:{}, //objeto q almacena el servicio registrado
            imagenes:[],
            directorioImagenes:'https://prixapi.prixisevents.com',
            idEmpresa:0,
            srcImgModal:'',
            token:null,
            isPopupOpen: false,
            images: [
                {
                id: 1,
                src: "@/assets/imagenes/ppp.jpg",
                alt: 'Imagen horizontal',
                },
                {
                id: 2,
                src: "@/assets/imagenes/ppp.jpg",
                alt: 'Imagen vertical',
                },
            ]
        }
    },
    created:async function(){
        this.obtenerToken()
        await this.obtenerServicios();
        await this.ObtenerAlturaImagen()
    },
    mounted: function() {
        this.$nextTick(() => {
            this.initializeMasonry();
        });
    },
    methods:{
        obtenerToken(){
            this.token = sessionStorage.getItem('token')
        },
        /**
         * Metodo para obtener el servicio
         */
        obtenerServicios(){
             return new Promise ((resolve,reject)=>{

                fetch('https://prixapi.prixisevents.com/api/servicios/' + this.$route.params.id,{
                    headers:{
                    'Authorization': 'Bearer '+ this.token
                    }
                })
                .then(respuesta=>respuesta.json())
                .then((respuestaDatos)=>{        
                        this.servicio = respuestaDatos.msg;   
                        this.imagenes = this.servicio.galerias[0].imagenes
                        this.idEmpresa = this.servicio.id_empresa                    
                        resolve()                
                })
                .catch(console.log)
            })
        },

        /**
         * Metodo para obtener la altura de la imagen de la seccion Quienes Somos
         */
        ObtenerAlturaImagen(){
            var tittle = document.getElementById('title-about').clientHeight;
            var text = document.getElementById('text-about').clientHeight;
            var btn = document.getElementById('btn-about').clientHeight;
            var altura = tittle + text + btn + 80

            if(altura <= 500){
                altura = 500
            }else{
                altura = 676
            }

            document.getElementById("img-about").style.height = altura +"px";
        },
        
        /**
         * Metodo para cambiar fotos alternativas
         */
        cambiarImagen(idCard){
            var card = document.querySelector('#card-'+idCard)
            var imgAlternative = card.querySelector(".card-content-img-alternatives")
            
            imgAlternative.addEventListener("click", (e)=>{
                //console.log(e.target)
                if(e.target.classList.contains('img-alt')){ 
                    var img = e.target.getAttribute('src')
                    card.querySelector("#img-muestra").setAttribute('src',img)
                    var nodeImgAlternatives = e.target.parentNode.parentNode
                    var listImgAlts = nodeImgAlternatives.querySelectorAll('.img-alt')
                    
                    for(var i=0; i<listImgAlts.length; i++){
                        if(listImgAlts[i].classList.contains('img-alt-selected')){
                            listImgAlts[i].classList.remove('img-alt-selected') 
                        }
                    }

                    e.target.classList.add('img-alt-selected')
                }
            })
        },

        /**
         * Metodos para maximizar una imagen
         */
        zoomImagen(event){

            this.$bvModal.show('myModalImagen')
            
            if(event.target.classList.contains('img-s')){
                var srcImg = event.target.getAttribute('src')
                this.srcImgModal = srcImg
            }
        },

        /**
         * Metodos para abrir y cerrar el popup
         */
        openPopup() {
            console.log("abri la imagen")
            this.isPopupOpen = true;
        },
        closePopup() {
            this.isPopupOpen = false;
        },

        /**Biblioteca Mansory pa la galeria */
        initializeMasonry() {
            this.masonry = new Masonry(this.$refs.grid, {
                itemSelector: '.grid-item',
                columnWidth: '.grid-item',
                percentPosition: true
            });
        },
        masonryLayout() {
            this.$nextTick(() => {
                this.masonry.layout();
            });
        }
    }
}

</script>

<style scoped>
/**Menu superior */
.top-main{
    background-color: black;
    width: 100%;
    color: #fff !important;
    padding: 15px 0;
    margin: 0;
}
.top-main .btn-link{
    color: #fac7c7;
}
/**Galeria Mansoty */
.gallery .grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 10px;
}
.gallery .grid-item {
  margin-bottom: 10px;
}
.gallery img {
  width: 100%;
  height: auto;
}
/**Seccion de galerias - Inicio */
/* .card-prixis{
    width: 300px;
    height: 470px;
    background-color: brown;
}

.card-prixis .card-img{
    width: 100%;
    height: 200px;
    background-color: blue;
}

.card-prixis .card-img img{
    width: 100%;
    height: 200px;
    max-width: 300px;
    max-height: 200px;
}

.card-img, .card-img-top, .card-img-bottom{
    max-width: 200px;
    height: auto;
}
.galeria .card{
    max-width: 200px;
    height: auto;
}

.card-prixis .card-content{
    width: 100%;
    height: 160px;
    background-color: #fff;
    margin: 0;
    font-family: sans-serif;
}

.card-prixis .card-content .card-content-img-alternatives{
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: start;
}

.card-prixis .card-content .card-content-img-alternatives .img-alternative{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-left: 5px;
}

.card-prixis .card-content .card-content-img-alternatives .img-alternative img{
    width: 60px;
    height: 60px;
    max-width: 60px;
    max-height: 60px;
    border-radius: 50%;
}

.card-prixis .card-content .card-content-info{
    width: 100%;
    height: 80px;
    margin: 0;
    padding-left: 10px;
}

.card-prixis .card-content .card-content-info h5,
.card-prixis .card-content .card-content-info h6,
.card-prixis .card-content .card-content-info p{
    margin: 0;
}

.card-prixis .card-content .card-content-info h5,
.card-prixis .card-content .card-content-info h6{
    margin-bottom: 5px;
}

.img-alt:hover{
    cursor: pointer;
}

.img-alt-selected{
    border: black solid 1px;
} */

.hr-st{
    display: visible;
    height: 30px;
    border-style: solid;
    border-color: black;
    border-width: 1px 0 0 0;
    border-radius: 20px;
    opacity: 1; 
    width:100%;
}

.hr-st::before{
    display: block;
    content: '';
    height: 30px;
    margin-top: -31px;
    border-style: solid;
    border-color: black;
    border-width: 0 0 1px 0;
    border-radius: 20px;
    opacity: 1; 
    width:100%;
}

/**Seccion de galerias - FIN */

/* Seccion sobre nosotros - INICIO */
.sobre-nosotros{
    background: #ffe0e0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    padding-bottom: 3rem;
    padding-top: 3rem;
}
#img-sobre-nosotros{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
    border-radius: 50%;
}
.sobre-nosotros-desc{
    text-align: center;
}

.sobre-nosotros .title-about{
    font-size: 4rem;
    margin-bottom: 10px;
    font-family: 'Great Vibes', cursive;
    color: black;
}

.sobre-nosotros .btn{
    background-color: black !important;
    color: #fcc8c8 !important;
    border: 2px solid black !important;
    padding: 10px;
}

.sobre-nosotros .text-about{
    font-size: 1.2rem;
    color: black;
}

.img-movil{
    display: none;
}

.atras{
    /* background-color: #ffe0e0; */
}

@media (max-width: 767px){
    
    /* .sobre-nosotros{
        margin: 0;
    }
    .div-img-sobre-nosotros{
        display: none !important;
    } */

    .cont-about-desc{
        margin: 0 !important;
        padding: 100px 15px !important;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .img-movil{
        display: block;
        background-image: url('@/assets/imagenes/ppp.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin-bottom: 30px;
    }
    #img-sobre-nosotros{
        width: 200px;
        height: 200px;
    }

    #btn-about{
        margin-top: 50px !important;
        width: 50% !important;
    }
}

/* Seccion sobre nosotros - FIN */

/**Modal para mostrar imagen */
.img-s{
    cursor: pointer;
}
.img-s:hover{
    opacity: 0.7;
}
#modal-img{
    min-width: 300px;
    min-height: auto;
}

/**Popup */
.popup{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top:0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 99;
}
.popup .btn{
    color: #fff !important;
    font-size: 2rem !important;
}
.body-popup{
    /* background-color: #fff;
    width: 600px;
    max-width: 100%;
    padding: 30px 50px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6); */
}

</style>








