<template>
  <div id="cont-app bg-success">
    <TopBar v-if="mostrar"/>
    <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'
import TopBar from './components/TopBar.vue'

export default {
  components:{
    Header,
    Footer,
    TopBar
  },
  data(){
    return{
      mostrar: false
    }
  },
  created:async function(){
    console.log("entro")
    this.ajustarPantalla()
    window.addEventListener("resize", this.tamañoDelCiente)

    if(sessionStorage.getItem('token')==null){
      this.authCLient()
    }
  },
  methods:{
    async authCLient(){
      const credencials = {
        "email": "prixclint@pxis.ftr",
	      "password": "970717word*"
      }

      var data = 
      await fetch('https://prixapi.prixisevents.com/api/auth/login',{ //localhost/prixis/api-prixis
          method:"POST",
          headers: {
          'Content-Type': 'application/json',
          },
          body: JSON.stringify(credencials)
      })

      var res = await data.json()
      var token = res.access_token

      sessionStorage.setItem('token',token)

    },
    /**
     * Ajusta el responsive al tamaño inicial de la pantalla del cliente
     */
    ajustarPantalla(){
        if(document.documentElement.clientWidth <= 767){
          this.mostrar = true
        }

        if(document.documentElement.clientWidth > 767){
          this.mostrar = false
        }
    },
    /**
     * Detecta los cambios en el tamaño de la pantalla del cliente
     */
    tamañoDelCiente(e) {
        if(document.documentElement.clientWidth <= 767){
          this.mostrar = true
        }

        if(document.documentElement.clientWidth > 767){
          this.mostrar = false
        }
    },
  }
}

window.addEventListener("scroll",function(){
  var nav = document.querySelector("nav")
  nav.classList.toggle("abajo",window.scrollY>100);
})
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

html{
  scroll-behavior: smooth;
}

body,html{
  padding: 0;
  margin: 0;
  min-height: 100%;
  height: 100%;
}

#app{
  padding: 0;
  margin: 0;
  min-height: 100%;
  height: 100%;
}

#cont-app{
  padding: 0;
  margin: 0;
  min-height: 100%;
  height: 100%;
}



.VueCarousel-pagination[data-v-438fd353] {
    display: none !important;
}

@media (max-width: 767px){
  html, body {
    width: 100% !important;
    overflow-x: none !important;
  } 
}
</style>
