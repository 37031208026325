import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
/**
 * Empresas 
 */
import Empresas from '../views/empresas/Inicio.vue'
/**
 * Empresas 
 */
import Directorio from '../views/empresas/Directorio.vue'
import ServiciosListar from '../views/servicios/Listar.vue'

import AboutUs from '../views/informacion/about.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  /**
   * Directorio de empresas
   */
  {
    path: '/directorio',
    name: 'Directorio',
    component: Directorio
  },

  /**
   * Empresas
   */
  {
    path: '/empresas/:id',
    name: 'Empresas',
    component: Empresas
  },

  /**
   * Servicios
   */
   {
    path: '/servicios/listar/:id', //Se le pasa el id del tipo de servicio
    name: 'Servicios/Listar',
    component: ServiciosListar
  },

  /**
   * Sobre nosotros
   */
   {
    path: '/quienes_somos', //Se le pasa el id del tipo de servicio
    name: 'QuienesSomos',
    component: AboutUs
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
