import { render, staticRenderFns } from "./about.vue?vue&type=template&id=5b5fe01c&scoped=true&"
var script = {}
import style0 from "./about.vue?vue&type=style&index=0&id=5b5fe01c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b5fe01c",
  null
  
)

export default component.exports