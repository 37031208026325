<template>
    <div class="home">

        <!-- Seccion que habla sobre Prixis -->
        <div class="sobre-nosotros row p-0 m-0">
            <div class="div-img-sobre-nosotros col-5 d-flex p-0">
                <div class="cont-img ml-auto">
                    <img id="img-about" src="@/assets/imagenes/ppp.jpg" alt="" class="rounded pb-0">
                </div>
            </div>

            <div class="cont-about-desc col-12 col-md-7 d-flex flex-column py-5">
                <div class="img-movil mx-auto"></div>
                <h2 class="title-about">Qué es Prixis?</h2>
                <p class="text-about">
                    ¡Hola! Permíteme presentarte a Prixis, 
                    un fascinante directorio web diseñado para celebrar momentos especiales. 
                    Con un enfoque en eventos festivos como bodas, quinces y cumpleaños, 
                    Prixis se convierte en tu compañero confiable para encontrar y 
                    organizar experiencias inolvidables. Con su amplia gama de servicios y opciones, 
                    Prixis facilita la planificación y coordinación de estos eventos, 
                    conectando a los usuarios con proveedores de confianza en un solo lugar. 
                    Ya sea que busques el lugar perfecto para una boda de ensueño, 
                    un salón de fiestas para una celebración de quince años inolvidable 
                    o los servicios de un talentoso organizador de eventos para hacer realidad tus ideas, 
                    Prixis está aquí para ayudarte a convertir tus sueños en realidad. Con un diseño intuitivo 
                    y amigable para el usuario, Prixis hace que la búsqueda y selección de servicios sea una 
                    experiencia emocionante y sin complicaciones. Déjate cautivar por la magia de Prixis y 
                    descubre cómo puede hacer de tus eventos festivos momentos inolvidables.
                </p>
            </div>
        </div>

        <!-- Seccion del directorio -->
        <div class="secc-directorio row p-0 m-0">
            <div class="col-12 col-md-6 datos-directorio">
                <div class="capa-datos-directorio">
                    <h3 class="tittle-direct">Directorio Prixis</h3>
                    <p class="text-direct mb-5">
                        ¿Buscas hacer de tu próximo evento festivo una experiencia inolvidable? 
                        ¡No busques más! Prixis es tu aliado perfecto. Con nuestro directorio web especializado 
                        en bodas, quinces y cumpleaños, encontrarás todo lo que necesitas en un solo lugar.
                        No pierdas tiempo buscando en diferentes lugares, 
                        ¡Permite que Prixis haga realidad tus sueños!</p>
                    <router-link :to="{name:'Directorio'}" class="btn btn-primary btn-lg">
                        Ir al Directorio
                    </router-link>
                </div>
            </div>

            <div class="col-md-6 img-directorio"></div>
        </div>

        <!-- Sección de Contactos -->
        <div class="secc-contacto row p-0 m-0" id="contacto-id">
            <div class="col-12 col-md-6 img-contacto"></div>
            <div class="col-12 col-md-6 datos-contactos">
                <h2 class="mb-4">Contáctanos!</h2>
                <div class="img-contact-movil"></div>
                <h4 class="mb-3">Ponte en contacto con los administradores de Prixis</h4>
                <p class="text-contact">Si tienes alguna duda sobre como usar Prixis, si necesitas incluir tu negocio de eventos en Prixis
                    y no sabes como, si tienes alguna queja o sujerencia, si necesitas asesoria en temas de programación,
                    para lo que necesites, aquí estamos. <b>CONTÁCTANOS!!!</b> 
                </p>
                
                <div class="contact">
                    <h5 class="mb-3"><b>Contactos</b></h5>
                    <p class="h5"><i class="fas fa-phone me-2"></i> +5353474966</p>
                    <p class="h5"><i class="fas fa-envelope me-2"></i> mariospv97@gmail.com</p>
                </div>
                <hr class="w-50 mx-auto">
                <div class="social h2">
                    <h5 class="mb-3"><b>Redes Sociales</b></h5>
                    <div class="d-flex justify-content-center">
                        <a href="https://api.whatsapp.com/send?phone=5353474966" target="_blank" class="mr-3"><i class="fab fa-whatsapp"></i></a>
                        <a href="https://www.linkedin.com/in/mario-sergio-padr%C3%B3n-vives-55b161240/" target="_blank" class="mr-3"><i class="fab fa-linkedin"></i></a>
                        <a href="https://github.com/MarioSergio97?tab=repositories" target="_blank" class="mr-3"><i class="fab fa-github"></i></a>          
                    </div>
                </div>
                
            </div>
        </div>
        
        <!-- <pre>{{text}}</pre> -->
    </div>
</template>

<script>
export default {
  name: 'HomeView',
  data(){
        return{
            text: 'linea 1. \nlinea 2',
        }
    } 
}
</script>

<style scoped>
html, body {
    width: 100%!important;
    overflow-x: hidden!important;
}
/**
 * Seccion Directorio
 */
.secc-directorio{
    text-align: center;
    padding: 50px 0 !important;
}

.secc-directorio .tittle-direct{
    color: black;
    font-size: 50px;
    font-weight: bold;
}

.secc-directorio .text-direct{
    font-size: 1.2rem;
    text-align: justify;
}

.datos-directorio{
    padding: 100px;
}

.img-directorio{
    background-image: url('@/assets/imagenes/directorio3.PNG');
    background-position: center;
    background-size: 60%;
    background-repeat: no-repeat;
}
.secc-directorio .btn.btn-primary.btn-lg{
    background-color: black !important;
    border-color: #fcc8c8 !important;
    color: #fcc8c8 !important;
}

@media (max-width: 767px){
    .secc-directorio{
        padding: 0 !important;
        margin: 0 !important;
    }

    .img-directorio{
        display: none;
    }

    .datos-directorio{
        background-image: url('../assets/imagenes/directorio3.PNG');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 0;
        width: 100%;
    }

    .capa-datos-directorio{
        background-color: rgba(0, 0, 0, 0.5);
        padding: 100px 15px;
        color: white;
    }

    .tittle-direct{
        color: white !important;
    }

    
    
}

/* Seccion sobre nosotros - INICIO */
.sobre-nosotros{
    padding: 50px 0 !important;
}

.sobre-nosotros-card{
    background-color: white;
}

.sobre-nosotros .cont-img{
    background: #ffe0e0;
    padding: 1px 1px 0 0;
}

.sobre-nosotros .col-5{
    background-image: url('@/assets/imagenes/particulas.png');
    background-repeat: repeat;
    background-position: center;
    background-size: auto;
}

.sobre-nosotros .cont-img img{
    width: auto;
    height: auto;
    min-width: 200px;
    max-width: 450px;
}

.sobre-nosotros .cont-about-desc{
    background-color: #ffe0e0;
    margin-bottom: 80px;
    word-wrap: break-word;
    border-top: black dashed 2px;
    border-bottom: black dashed 2px;
}

.sobre-nosotros .cont-about-desc .title-about{
    text-align: center;
    font-size: 40px;
    margin-bottom: 25px;
    font-weight: bold;
}

.sobre-nosotros .cont-about-desc .text-about{
    font-size: 1.2rem;
    text-align: justify;
}

.img-movil{
    display: none;
}

@media (max-width: 767px){
    .sobre-nosotros{
        margin: 0 !important;
        padding: 0 !important;

    }
    .div-img-sobre-nosotros{
        display: none !important;
    }

    .cont-about-desc{
        margin: 0 !important;
        padding: 100px 15px !important;
    }

    .cont-about-desc p{
        text-align: justify !important;
    }

    .img-movil{
        display: block;
        background-image: url('@/assets/imagenes/ppp.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin-bottom: 30px;
    }
}


/**
 * Seccion Contacto
 */
.secc-contacto{
    text-align: center;
    background-color: #ffe0e0;
}

.secc-contacto .text-contact{
    font-size: 20px;
} 

.secc-contacto h2{
    color: black;
    font-size: 50px;
    font-weight: bold;
}

.secc-contacto h4{
    color: black;
    font-size: 30px;
    font-weight: bold;
}

.datos-contactos{
    padding: 100px;
}

 .img-contacto{
    background-image: url('../assets/imagenes/contacto2.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
 }

 .img-contact-movil{
    display: none;
 }

 @media (max-width: 767px){
    .secc-contacto{
        margin: 0;
    }
    .img-contacto{
        display: none;
    }

    .img-contact-movil{
        display: block;
        background-image: url('../assets/imagenes/contacto2.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        widows: 300px;
        height: 300px;
    }

    .datos-contactos{
        padding: 100px 15px;
    }

    .text-contact{
        text-align: justify;
        margin-bottom: 25px;
    }
 }

</style>
