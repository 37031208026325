<template>
    <nav class="navegacion">
        <input type="checkbox" id="check">
        <label for="check" class="checkbtn">
            <i class="fas fa-bars"></i>
        </label>

        <label class="logo">
            <router-link :to="{name:'home'}"> Prixis </router-link> 
        </label>

        <ul>
            <li>
                <a v-on:click="uncheck('home')">
                     Inicio 
                </a>
            </li>
            <li>
                <a v-on:click="uncheck('Directorio')">
                    Directorio
                </a>
            </li>
            <li>
                <a v-on:click="uncheck('QuienesSomos')">
                    Sobre Nosotros
                </a>
            </li>
            <li><a v-on:click="uncheck('contacto')">Contacto</a></li>
        </ul>
    </nav>
</template>

<script>
export default {
   methods:{
        uncheck(router){
            if(router != 'contacto'){
                document.getElementById('check').checked = false

                this.$router.replace({
                    name: router
                })
            }else{
                window.location.href = "http://prixisevents.com#contacto-id";
            }
            
        }
    }
}
</script>

<style scoped>
*{
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}
nav{
    font-family: 'arial';
    font-weight: 700;
    background: black;
    height: 10vh;
    width: 100%;
}
label.logo{
    color: #fcc8c8 !important;
    font-size: 30px;
    padding: 0 100px;
    font-weight: bold;
    line-height: 10vh;
}
label.logo a:hover, label.logo a:hover {
    color: #fcc8c8 !important;
}
.logo .router-link-exact-active.router-link-active{
    color: #fcc8c8 !important;
}
nav ul{
    float: right;
    margin-right: 20px;
}
nav ul li{
    display: inline-block;
    line-height: 10vh;
    margin: 0 5px;
}
nav ul li a{
    color: #fcc8c8;
    font-size: 17px;
    padding: 7px 13px;
    border-radius: 3px;
    text-transform: uppercase;
}
a.active, a:hover{
    background: #ffe0e0;
    transition: .5s;
    text-decoration: none;
    color: black;
}
.checkbtn{
    font-size: 30px;
    color: #fcc8c8;
    float: right;
    line-height: 10vh;
    margin-right: 40px;
    cursor: pointer;
    display: none;
}
#check{
    display:none;
}

@media (max-width: 767px){
    label.logo{
        font-size: 25px;
        padding-left: 50px;
    }
    nav ul li a{
        font-size: 16px;
        color: black;
    }
    .checkbtn{
        display: block;
    }
    ul{
        position: absolute;
        z-index: 999;
        width: 100%;
        height: 100vh;
        background: #ffe0e0;
        top: 10vh;
        left: -100%;
        text-align: center;
        transition: all .5s;
    }
    nav ul li{
        display: block;
        margin: 50px 0;
        line-height: 30px;
    }
    nav ul li a{
        font-size: 20px;
    }
    a:hover, a.active{
        background: black;
        color: #ffe0e0;
    }
    #check:checked ~ ul{
        left: 0;
    }
}

</style>